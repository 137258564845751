import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBookingTilesWrapperProps } from './booking-tiles';

const BookingTiles = lazy(async () => import('./booking-tiles'));

const el = '.fa-component--booking-tiles';
const DOM = document.querySelector(el);
const token: HTMLInputElement = document.querySelector('input[name="__RequestVerificationToken"]')!;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.getAttribute('data-props')!) as IBookingTilesWrapperProps;

        createRoot(DOM).render(<BookingTiles {...props} antiForgeryToken={token.value} />);
    }
};

export default {
    render,
};
