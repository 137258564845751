import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IValidationModal } from './validation-modal';

const ValidationModal = lazy(async () => import('./validation-modal'));

const el = '.fa-component--validation-modal';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.getAttribute('data-props')!) as IValidationModal;

        createRoot(DOM).render(<ValidationModal {...props} />);
    }
};

export default {
    render,
};
