import './foundation/polyfill/polyfill';

import BreadCrumb from './components/C05-breadcrumbs/C05-breadcrumbs.init';
import NewsList from './components/C14-news-list/news-list.init';
import JobList from './components/C15-job-list/job-list.init';
import StatusCodePage from './components/C17-status-code-page/C17-status-code-page.init';
import CountrySiteBanner from './components/C20-country-site-banner/C20-country-site-banner.init';
import OfficeList from './components/M14-office-list/M14-office-list.init';
import MediaListComponent from './components/M17-media-list/M17-media-list.init';
import DocumentReports from './components/M19-document-reports/M19-document-reports.init';
import TheHeader from './components/TheHeader/TheHeader.init';
import BookingAccordion from './components/_booking/booking-accordion/booking-accordion.init';
import DropdownWithLanguage from './components/dropdown-with-language-selector/dropdown-with-language-selector.init';
import DropdownWithSearch from './components/dropdown-with-search/dropdown-with-search.init';
import SearchComponent from './components/search-component/search-component.init';
import SearchFilterOptions from './components/search-filter-options/search-filter-options.init';
import SearchComponentNoResult from './foundation/components/search-component-no-result/search-component-no-result.init';
// Rewrite class to functional component, add lazy loading
import Accordion from './components/M24-accordion/M24-accordion.init.list';
import SocialShare from './foundation/components/fa-social-share/fa-social-share.init';
// Rewrite class to functional component, add lazy loading
import WarningBanner from './components/C21-warning-banner/C21-warning-banner.init';
import VideoPlayer from './components/M06-video-player/video-player.init';
import ImageAccordion from './components/M18-image-accordion/M18-image-accordion.init';
import HomeHeroVideo from './components/M43-home-hero-video/home-hero-video.init';
import ListOfVideos from './components/M57-list-of-videos-block/list-of-videos.init';
import BenefitCard from './components/SelfService/benefit-card/benefit-card.init';
import BenefitJumbotron from './components/SelfService/benefit-jumbotron/benefit-jumbotron.init';
import ConsentBlock from './components/SelfService/consent-block/consent-block.init';
import ConsentItem from './components/SelfService/consent-item/consent-item.init';
import ConsentOverview from './components/SelfService/consent-overview/consent-overview.init';
import ContactBlock from './components/SelfService/contact-block/contact-block.init';
import CoveredObjectJumbotron from './components/SelfService/covered-object-jumbotron/covered-object-jumbotron.init';
import CoveredVehiclesBlock from './components/SelfService/covered-vehicles-block/covered-vehicles-block.init';
import ErrorCard from './components/SelfService/error-card/error-card.init';
import ErrorMessage from './components/SelfService/error-message/error-message.init';
import FaqBlock from './components/SelfService/faq-block/faq-block.init';
import FaqCollectionBlock from './components/SelfService/faq-collection-block/faq-collection-block.init';
import FaqItem from './components/SelfService/faq-item/faq-item.init';
import HowToBlock from './components/SelfService/how-to-block/how-to-block.init';
import HowToItem from './components/SelfService/how-to-item/how-to-item.init';
import Infoband from './components/SelfService/infoband/infoband.init';
import LicensePlate from './components/SelfService/license-plate/license-plate.init';
import PersonalBenefitList from './components/SelfService/personal-benefit-list/personal-benefit-list.init';
import PersonalJumbotron from './components/SelfService/personal-jumbotron/personal-jumbotron.init';
import PersonalProductList from './components/SelfService/personal-product-list/personal-product-list.init';
import ProductCard from './components/SelfService/product-card/product-card.init';
import ProductJumbotron from './components/SelfService/product-jumbotron/product-jumbotron.init';
import PromoBlock from './components/SelfService/promo-block/promo-block.init';
import SectionHeading from './components/SelfService/section-heading/section-heading.init';
import SettingsBlock from './components/SelfService/settings-block/settings-block.init';
import ValidationModal from './components/SelfService/validation-modal/validation-model.init';
import BookingHero from './components/_booking/booking-hero/booking-hero.init';
import BookingMain from './components/_booking/booking-main/booking-main.init';
import BookingSectionHeadline from './components/_booking/booking-section-headline/booking-section-headline.init';
import BookingTiles from './components/_booking/booking-tiles/booking-tiles.init';
import FutureBookings from './components/_booking/future-bookings/future-bookings.init';
import NextBooking from './components/_booking/next-booking/next-booking.init';
import DepartmentFinder from './components/fa-department-finder/fa-department-finder.init';
import Employees from './components/fa-employees/fa-employees.init';
import GoogleMap from './components/fa-google-maps/fa-google-maps.init';
import Glide from './components/fa-page-hero-cs/fa-page-hero-cs-carousel.init';
import SickLeave from './components/fa-sickleave/fa-sickleave.init';
import Toast from './components/toast/toast.init';

BookingAccordion.render();

TheHeader.render();

DropdownWithSearch.render();

DropdownWithLanguage.render();

CountrySiteBanner.render();

SearchFilterOptions.render();

SearchComponent.render();

SearchComponentNoResult.render();

BreadCrumb.prepareData();
BreadCrumb.render();

OfficeList.render();

MediaListComponent.render();

NewsList.render();

JobList.render();

DocumentReports.render();

StatusCodePage.render();

SocialShare.render();

Accordion.renderList();

ImageAccordion.render();

Glide.render();

BookingHero.render();

NextBooking.render();

BookingTiles.render();

BookingSectionHeadline.render();

ProductJumbotron.render();

SectionHeading.render();

BookingMain.render();

Infoband.render();

PersonalBenefitList.render();

BenefitCard.render();

ErrorMessage.render();

ErrorCard.render();

CoveredObjectJumbotron.render();

BenefitJumbotron.render();

FaqCollectionBlock.render();

FaqBlock.render();

FaqItem.render();

HowToBlock.render();

HowToItem.render();

ProductCard.render();

PersonalProductList.render();

FutureBookings.render();

CoveredVehiclesBlock.render();

LicensePlate.render();

PromoBlock.render();

PersonalJumbotron.render();

DepartmentFinder.render();

GoogleMap.render();

Employees.render();

SickLeave.render();

WarningBanner.render();

ContactBlock.render();

SettingsBlock.render();

ConsentBlock.render();

ConsentItem.render();

ConsentOverview.render();

Toast.render();

ValidationModal.render();

VideoPlayer.render();

ListOfVideos.render();

HomeHeroVideo.render();
