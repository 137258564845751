import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const SearchComponent = lazy(async () => import('./search-component'));

const el = '.fa-component--SearchComponent';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const config = {
            endpoint: DOM.getAttribute('data-endpoint')!,
            type: DOM.getAttribute('data-type')!,
            currentContentGuid: DOM.getAttribute('data-currentContentGuid')!,
            labels: JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels,
        };

        createRoot(DOM).render(<SearchComponent config={config} />);
    }
};

export default {
    render,
};
