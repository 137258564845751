import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';
import type { IHeaderProps } from './TheHeader';

const TheHeader = lazy(async () => import('./TheHeader'));

const el = '.fa-component--the-header';
const DOM: HTMLElement = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;
        const props = JSON.parse(DOM.dataset.props ?? '{}') as IHeaderProps;

        createRoot(DOM).render(<TheHeader {...props} labels={labels} />);
    }
};

export default {
    render,
};
