import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IProductCardProps } from './product-card';

const ProductCard = lazy(async () => import('./product-card'));

const el = '.fa-component--single-product-card';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IProductCardProps;

            // Some props are still missing
            createRoot(component).render(<ProductCard {...props} />);
        });
    }
};

export default {
    render,
};
