import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBookingMain } from './booking-main';

// Importing BookingMain using React.lazy
const BookingMain = lazy(async () => import('./booking-main'));

const el = '.fa-component--booking-main';
const DOM = document.querySelector(el);
const token: HTMLInputElement = document.querySelector('input[name="__RequestVerificationToken"]')!;

const render = function () {
    if (DOM) {
        const props: IBookingMain = JSON.parse(DOM.getAttribute('data-props') ?? '{}') as IBookingMain;

        createRoot(DOM).render(<BookingMain {...props} antiForgeryToken={token.value} />);
    }
};

export default {
    render,
};
