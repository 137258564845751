import * as React from 'react';
import { Component } from 'react';

import IconMissingIcon from './components/iconMissingIcon';
import IconShare from './components/iconShare';
import IconEmail from './components/iconEmail';
import IconFacebook from './components/iconFacebook';
import IconLinkedin from './components/iconLinkedin';
import IconTwitter from './components/iconTwitter';

interface SocialShareProps {
    socialShareList: Array<{
        name: string;
        socialStylingClass: string;
        tooltip: string;
        email: string;
    }>;
    pageUrl: string;
    pageHeader: string;
    soMeImageUrl: string;
    colorScheme: string;
}

class SocialShareState {
    popupToggle!: boolean;
    displayPopup!: boolean;
}

class SocialShare extends Component<SocialShareProps, SocialShareState> {
    private ref: any;

    constructor(props: SocialShareProps, state: SocialShareState) {
        super(props, state);
        this.ref = React.createRef();
        this.state = {
            displayPopup: false,
            popupToggle: false,
        };
    }

    readonly openDropdown = () => {
        const current = this.ref.current as any;

        if (current.querySelector('div')) {
            this.setState({
                displayPopup: true,
            });
        }
        document.addEventListener('click', this.closeDropdown);
    };

    readonly closeDropdown = (event: Event) => {
        const _event = event as unknown as React.MouseEvent<HTMLElement>;
        const current = this.ref.current as any;
        const socialShareElement = current.querySelector('.fa-social-share__list');

        if (current && !socialShareElement.contains(_event.target)) {
            this.setState({
                displayPopup: false,
            });
            document.removeEventListener('click', this.closeDropdown);
            document.removeEventListener('keyup', this.closeDropdown);
        }
    };

    readonly socialShareIcons = (iconName: string) => {
        let SocialIcon;

        switch (iconName) {
            case 'email':
                SocialIcon = <IconEmail />;
                break;
            case 'facebook':
                SocialIcon = <IconFacebook />;
                break;
            case 'linkedin':
                SocialIcon = <IconLinkedin />;
                break;
            case 'twitter':
                SocialIcon = <IconTwitter />;
                break;
            default:
                SocialIcon = <IconMissingIcon />;
        }
        return SocialIcon;
    };

    readonly renderSocialSharePopup = () => {
        const { displayPopup } = this.state;
        let socialShareList = this.props.socialShareList;
        let socialSharePageUrl = this.props.pageUrl;
        let socialSharePageHeader = this.props.pageHeader;
        let socialShareSoMeImageUrl = this.props.soMeImageUrl;
        let email = '';

        return (
            <div className={'fa-social-share__list' + (displayPopup ? ' is-visible' : '')}>
                {socialShareList.map((item, index) => {
                    return item.name.toLowerCase() == 'email' ? (
                        <a
                            className={`fa-social-share__item-link ${item.socialStylingClass}`}
                            href={
                                'mailto:' +
                                email +
                                '?subject=' +
                                socialSharePageHeader +
                                '&body=Link: ' +
                                socialSharePageUrl
                            }
                            key={index}
                        >
                            <span className="icon-wrapper-red" title={`${item.tooltip}`}>
                                {this.socialShareIcons(item.name.toLocaleLowerCase())}
                            </span>
                            {item.name}
                        </a>
                    ) : (
                        <a
                            className={`fa-social-share__item-link ${item.socialStylingClass}`}
                            data-addthis-url={socialSharePageUrl}
                            title="&nbsp;"
                            data-addthis-title="&nbsp;"
                            data-addthis-media={socialShareSoMeImageUrl}
                            key={index}
                        >
                            <span className="icon-wrapper-red" title={`${item.tooltip}`}>
                                {this.socialShareIcons(item.name.toLocaleLowerCase())}
                            </span>
                            {item.name}
                        </a>
                    );
                })}
            </div>
        );
    };

    readonly renderSingleSocialShare = () => {
        let socialShareList = this.props.socialShareList;
        let socialSharePageUrl = this.props.pageUrl;
        let socialSharePageHeader = this.props.pageHeader;
        let socialShareSoMeImageUrl = this.props.soMeImageUrl;
        let colorScheme = this.props.colorScheme;
        let email = '';

        return (
            <div className="fa-social-share__single-item-container">
                {socialShareList.map((item, index) => {
                    return item.name.toLowerCase() == 'email' ? (
                        <a
                            className={`fa-social-share__item-link fa-social-share__item-link--single-item ${item.socialStylingClass}`}
                            href={
                                'mailto:' +
                                email +
                                '?subject=' +
                                socialSharePageHeader +
                                '&body=Link: ' +
                                socialSharePageUrl
                            }
                            key={index}
                        >
                            <span
                                className={`${
                                    colorScheme == 'simplePageHeroColor' ? 'icon-wrapper-red' : 'icon-wrapper'
                                }`}
                                title={`${item.tooltip}`}
                            >
                                {this.socialShareIcons(item.name.toLocaleLowerCase())}
                            </span>
                        </a>
                    ) : (
                        <a
                            className={`fa-social-share__item-link fa-social-share__item-link--single-item ${item.socialStylingClass}`}
                            data-addthis-url={socialSharePageUrl}
                            data-addthis-title="&nbsp;"
                            title="&nbsp;"
                            data-addthis-media={socialShareSoMeImageUrl}
                            key={index}
                        >
                            <span
                                className={`${
                                    colorScheme == 'simplePageHeroColor' ? 'icon-wrapper-red' : 'icon-wrapper'
                                }`}
                                title={`${item.tooltip}`}
                            >
                                {this.socialShareIcons(item.name.toLocaleLowerCase())}
                            </span>
                        </a>
                    );
                })}
            </div>
        );
    };

    readonly renderSocialShareElement = () => {
        let socialShareList = this.props.socialShareList;
        let socialShareJSXElement: JSX.Element | string = '';

        socialShareList.length > 3
            ? (socialShareJSXElement = (
                  <section ref={this.ref} className="fa-social-share">
                      <label className="fa-social-share__trigger" onClick={this.openDropdown}>
                          <IconShare />
                      </label>
                      {this.renderSocialSharePopup()}
                  </section>
              ))
            : (socialShareJSXElement = this.renderSingleSocialShare());
        return socialShareJSXElement;
    };

    render() {
        return this.renderSocialShareElement();
    }
}

export default SocialShare;
