import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBookingHeadlineProps } from './booking-section-headline';

const BookingsectionHeadline = lazy(async () => import('./booking-section-headline'));

const el = '.fa-component--booking-section-headline';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IBookingHeadlineProps;

            createRoot(component).render(
                <BookingsectionHeadline title={props.title} readmore={props.readmore} number={props.number} />,
            );
        });
    }
};

export default {
    render,
};
