import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { ISectionHeadingProps } from './section-heading';

const SectionHeading = lazy(async () => import('./section-heading'));

const el = '.fa-component--section-heading';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as ISectionHeadingProps;

            createRoot(component).render(<SectionHeading title={props.title} text={props.text} icon={props.icon} />);
        });
    }
};

export default {
    render,
};
