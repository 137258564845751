// @ts-ignore
import Glide from '../../foundation/vendor/glide/glide.esm.js';

const el = '.fa-page-hero-carousel--js';
const DOM = document.querySelector(el);

const GlideParams = {
    type: 'slider',
    bound: true,
    rewind: true,
    perView: 1,
    autoplay: true,
};

const render = () => {
    if (DOM) {
        new Glide('.glide', GlideParams).mount();
    }
};

export default {
    render,
};
