import AccordionInit from './M24-accordion.init';

const el = '.fa-component--accordion';

const renderList = function () {
    const accordionList = document.querySelectorAll(el);

    accordionList.forEach((DOM: any) => {
        const element = DOM as HTMLDivElement;

        AccordionInit.render(element);
    });
};

export default { renderList };
