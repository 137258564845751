// Verify whether the label requested exists within the label object
const verifyLabel = (requestedLabel: string, labelsObj: any) => {
    const doesExist = Object.keys(labelsObj && typeof labelsObj === 'object' ? labelsObj : {}).some((element) =>
        element.indexOf(requestedLabel)
    );

    return doesExist ? labelsObj[requestedLabel] : requestedLabel;
};

export default verifyLabel;
