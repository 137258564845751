import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IPersonalBenefitListProps } from './personal-benefit-list';

const PersonalBenefitList = lazy(async () => import('./personal-benefit-list'));

const el = '.fa-component--personal-benefit-list';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IPersonalBenefitListProps;

            createRoot(component).render(<PersonalBenefitList {...props} />);
        });
    }
};

export default {
    render,
};
