import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';
import type { IStatusCodePageProps } from './C17-status-code-page';

const StatusCodePage = lazy(async () => import('./C17-status-code-page'));

const el = '.fa-component--status-code-page';
const DOM: HTMLElement = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;
        const props = JSON.parse(DOM.dataset.props ?? '{}') as IStatusCodePageProps;

        createRoot(DOM).render(
            <StatusCodePage
                labels={labels}
                searchPageUrl={props.searchPageUrl}
                pageHeader={props.pageHeader}
                pageTitle={props.pageTitle}
                pageContent={props.pageContent}
                hideSearch={props.hideSearch}
            />,
        );
    }
};

export default {
    render,
};
