/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { createRoot } from 'react-dom/client';
import VideoPlayer from './video-player';

const el = '.fa-component--video-player';
const DOM = document.querySelectorAll(el);

const render = () => {
    if (DOM.length) {
        DOM.forEach((player) => {
            const data = player.getAttribute('data-props')
                ? JSON.parse(player.getAttribute('data-props') ?? '{}')
                : undefined;

            const props = {
                twentyThreeVideoPlayerCode: data?.twentyThreeVideoPlayerCode ?? undefined,
                header: data?.header ?? '',
                abstract: data?.abstract ?? '',
                size: data?.size,
                overlay: data?.size === 'Medium',
                videoFunctionCookieRequirementText: data?.videoFunctionCookieRequirementText ?? '',
                videoFunctionCookieRequirementButtonText: data?.videoFunctionCookieRequirementButtonText ?? '',
                videoCookiePlaceholderIconUrl: data?.videoCookiePlaceholderIconUrl ?? '',
                guid: data?.guid ?? '',
                storybook: false,
            };

            createRoot(player).render(<VideoPlayer {...props} />);
        });
    }
};

export default {
    render,
};
