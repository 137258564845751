import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const MediaListComponent = lazy(async () => import('./M17-media-list'));

const el = '.fa-component--media-list';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const testdata = JSON.parse(DOM.getAttribute('data-testdata')!) as Search.IMappedSearchResult[];
        const totalResults = parseInt(DOM.getAttribute('data-totalresults')!, 10);
        const pagesLoaded = parseInt(DOM.getAttribute('data-pagesLoaded')!, 10);
        const totalPages = parseInt(DOM.getAttribute('data-totalPages')!, 10);
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;

        createRoot(DOM).render(
            <MediaListComponent
                result={testdata}
                labels={labels}
                dataTotalResults={totalResults}
                pagesLoaded={pagesLoaded}
                dataTotalPages={totalPages}
            />,
        );
    }
};

export default {
    render,
};
