import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IPromoBlockProps } from './promo-block';

const PromoBlock = lazy(async () => import('./promo-block'));

const el = '.fa-component--promo-block';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props') ?? '{}') as IPromoBlockProps;

            createRoot(component).render(<PromoBlock {...props} />);
        });
    }
};

export default {
    render,
};
