import * as React from 'react';

export interface IVideoPlaceholderImageProps {
    src: string;
    alt: string;
}

export default function VideoPlaceholderImage({ src, alt }: IVideoPlaceholderImageProps) {
    return (
        <div className="fa-video-placeholder-image">
            <div className="fa-video-overlay-effect" />
            {src && (
                <figure>
                    <img src={src} alt={alt} />
                </figure>
            )}
            <div className="fa-video-placeholder-image__button">
                <button className="fa-video-play-button fa-js--video-play-button">
                    <span className="fa-video-play-button__icon"></span>
                </button>
            </div>
        </div>
    );
}
