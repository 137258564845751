import * as React from 'react';

const SvgCookieFallback = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 53 50">
        <path
            fill="#FF1E2D"
            d="m47.197 28.745 1.48.242a1.5 1.5 0 0 0-1.826-1.702l.346 1.46ZM29.379 2.519l1.154.958a1.5 1.5 0 0 0-.837-2.425L29.38 2.52Zm6.654 20.127 1.416-.497a1.5 1.5 0 0 0-1.061-.96l-.355 1.457Zm9.683 5.856C44.045 38.71 35.181 46.5 24.5 46.5v3c12.174 0 22.272-8.878 24.177-20.513l-2.96-.485ZM24.5 46.5C12.626 46.5 3 36.874 3 25H0c0 13.531 10.969 24.5 24.5 24.5v-3ZM3 25C3 13.126 12.626 3.5 24.5 3.5v-3C10.969.5 0 11.469 0 25h3ZM24.5 3.5c1.567 0 3.093.167 4.563.485l.633-2.933A24.588 24.588 0 0 0 24.5.5v3Zm3.726-1.94A13.949 13.949 0 0 0 25 10.5h3c0-2.672.95-5.117 2.533-7.023L28.225 1.56ZM25 10.5c0 6.59 4.55 12.112 10.678 13.603l.71-2.915C31.573 20.016 28 15.674 28 10.5h-3Zm9.618 12.642C36.12 27.425 40.199 30.5 45 30.5v-3a8.004 8.004 0 0 1-7.551-5.35l-2.831.992ZM45 30.5c.873 0 1.725-.102 2.542-.296l-.69-2.919A8.026 8.026 0 0 1 45 27.5v3Z"
        />
        <circle cx="38.5" cy="8" r="2.5" stroke="#FF1E2D" strokeWidth="3" />
        <circle cx="17" cy="33.5" r="4" stroke="#FF1E2D" strokeWidth="3" />
        <circle cx="16" cy="16.5" r="3" stroke="#FF1E2D" strokeWidth="3" />
        <circle cx="29" cy="30.5" r="1.5" stroke="#FF1E2D" strokeWidth="2" />
        <circle cx="50" cy="10.5" r="1.5" stroke="#FF1E2D" strokeWidth="2" />
    </svg>
);

export default SvgCookieFallback;
