import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { ICoveredVehiclesBlockProps } from './covered-vehicles-block';

const CoveredVehiclesBlock = lazy(async () => import('./covered-vehicles-block'));

const el = '.fa-component--covered-vehicles-block';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props') ?? '{}') as ICoveredVehiclesBlockProps;

            createRoot(component).render(<CoveredVehiclesBlock {...props} />);
        });
    }
};

export default {
    render,
};
