import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IInfobandProps } from './infoband';

const Infoband = lazy(async () => import('./infoband'));

const el = '.fa-component--infoband';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IInfobandProps;

            createRoot(component).render(
                <Infoband image={props.image} description1={props.description1} description2={props.description2} />,
            );
        });
    }
};

export default {
    render,
};
