import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBreadCrumbConfigData } from 'types';

const Breadcrumbs = lazy(async () => import('./C05-breadcrumbs'));

const el = '.fa-component--breadcrumb';
const DOM = document.querySelector(el)!;

const crumbsPopupDataMapper = function (element: Element) {
    const link = element.querySelector('a');
    const returnValue: { href: string; text: string } = { href: '', text: '' };

    if (link) {
        returnValue.href = link.getAttribute('href')!;
        returnValue.text = link.innerText;
    }

    return returnValue;
};

const prepareData = function (): any {
    if (DOM) {
        const crumbs = DOM.querySelectorAll('.fa-component--breadcrumb-item');

        let crumbsLineData;
        let crumbsPopupData;

        if (crumbs?.length) {
            const crumbsAsArray = Array.from(crumbs);

            crumbsPopupData = crumbsAsArray.slice(1, crumbs.length - 1).map(crumbsPopupDataMapper);

            const crumbsFirst = crumbsAsArray.slice(0, 1)[0].querySelector('a')!;
            const crumbsLast = crumbsAsArray.slice(crumbs.length - 1)[0].querySelector('span')!;

            if (crumbsAsArray.length <= 2) {
                crumbsLineData = [
                    {
                        href: crumbsFirst?.getAttribute('href'),
                        text: crumbsFirst?.innerText,
                    },
                    {
                        text: crumbsLast.innerText,
                    },
                ];
            } else if (crumbsFirst !== null && crumbsLast !== null) {
                crumbsLineData = [
                    {
                        href: crumbsFirst.getAttribute('href'),
                        text: crumbsFirst.innerText,
                    },
                    {
                        text: '...',
                        type: 'openPopup',
                    },
                    {
                        text: crumbsLast.innerText,
                    },
                ];
            } else {
                crumbsLineData = [
                    {
                        href: '',
                        text: '',
                    },
                    {
                        text: '...',
                        type: 'openPopup',
                    },
                    {
                        text: '',
                    },
                ];
            }
        }

        return {
            crumbsPopupData,
            crumbsLineData,
        };
    }
};

const preparePlaceholder = function () {
    const newChild = document.createElement('div');

    if (DOM) {
        const parent = DOM.parentElement!;

        newChild.classList.add('fa-component--mobile-breadcrumb');
        parent.appendChild(newChild);
    }

    return newChild;
};

const render = function () {
    if (DOM) {
        const config = prepareData() as IBreadCrumbConfigData;
        const placeholder = preparePlaceholder();

        createRoot(placeholder).render(<Breadcrumbs config={config} />);
    }
};

export default {
    render,
    prepareData,
};
