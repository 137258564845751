import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IAccordionProps } from './M24-accordion';

const Accordion = lazy(async () => import('./M24-accordion'));

const render = function (element: HTMLElement) {
    if (element) {
        const props = JSON.parse(element.dataset.props ?? '{}') as IAccordionProps;

        createRoot(element).render(<>{props?.map((l: any, i: any) => <Accordion item={l} key={i} />)}</>);
    }
};

export default {
    render,
};
