import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { SickLeaveViewModel } from 'types';

const SickLeave = lazy(async () => import('./fa-sickleave').then((module) => ({ default: module.SickLeave })));

const el = '.fa-sickleave';
const DOM = document.querySelector(el);

const render = () => {
    if (DOM) {
        const viewModel = JSON.parse(DOM.getAttribute('sick-leave-data') ?? '') as SickLeaveViewModel;

        createRoot(DOM).render(<SickLeave viewModel={viewModel} />);
    }
};

export default { render };
