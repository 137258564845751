import type { IVideoPlayerProps } from '@components/M06-video-player/video-player';
// eslint-disable-next-line import/no-unresolved
import VideoPlayer from '@components/M06-video-player/video-player';
// eslint-disable-next-line import/no-unresolved
import CookiesBlockedPlaceholder from '@components/cookies-blocked-placeholder/cookies-blocked-placeholder';
import * as React from 'react';

export interface IListOfVideosProps {
    videos: IVideoPlayerProps[];
    defaultRows: number;
    header: string;
    loadMore: string;
    videoFunctionCookieRequirementText?: string;
    videoFunctionCookieRequirementButtonText?: string;
    videoCookiePlaceholderIconUrl?: string;
    storybook?: boolean;
}

export default function ListOfVideos({
    header,
    videos,
    defaultRows = 2,
    loadMore,
    videoFunctionCookieRequirementText,
    videoFunctionCookieRequirementButtonText,
    videoCookiePlaceholderIconUrl,
    storybook = false,
}: IListOfVideosProps) {
    const [numberOfVideos, setNumberOfVideos] = React.useState(defaultRows * 3);
    const [cookieConsent, setCookieConsent] = React.useState(false);

    React.useEffect(() => {
        if (storybook) {
            setCookieConsent(true);
        } else {
            handleCookieConsent();
        }
    }, []);

    const handleCookieConsent = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        const consent = CookieInformation.getConsentGivenFor('cookie_cat_functional');

        window.addEventListener(
            'CookieInformationConsentGiven',
            () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
                setCookieConsent(CookieInformation.getConsentGivenFor('cookie_cat_functional'));
            },
            false,
        );

        setCookieConsent(Boolean(consent));
    };

    const showAllVideos = () => {
        setNumberOfVideos(videos.length);
    };

    return (
        <section className={`fa-list-videos`}>
            {cookieConsent ? (
                <div className="fa-list-videos__content fa-block">
                    <h2 className="fa-list-videos__content__title">{header}</h2>
                    <List videos={videos} numberOfResults={numberOfVideos} initNumberOfVideos={defaultRows * 3} />
                    {numberOfVideos < videos.length && (
                        <div className="fa-list-videos__action">
                            <button onClick={showAllVideos} className="fa-atom-button fa-atom-button--color-red">
                                {loadMore}
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <CookiesBlockedPlaceholder
                    header={header ?? ''}
                    abstract={''}
                    text={videoFunctionCookieRequirementText ?? ''}
                    buttonText={videoFunctionCookieRequirementButtonText ?? ''}
                    icon={videoCookiePlaceholderIconUrl ?? ''}
                    size={'Large'}
                />
            )}
        </section>
    );
}

interface IListProps {
    videos: IVideoPlayerProps[];
    numberOfResults?: number;
    initNumberOfVideos: number;
}

export function List({ videos, numberOfResults, initNumberOfVideos }: IListProps) {
    const videosToDisplay = videos.slice(0, numberOfResults);
    const listRef = React.useRef<HTMLUListElement>(null);

    React.useEffect(() => {
        if (numberOfResults && numberOfResults > initNumberOfVideos) {
            const buttons = listRef.current?.querySelectorAll('.fa-js--video-play-button');

            const button = buttons![initNumberOfVideos - 1] as HTMLButtonElement | null;

            button?.focus();
        }
    }, [numberOfResults]);

    return (
        <ul ref={listRef} className="fa-list-videos__content__list">
            {videosToDisplay.map((video: IVideoPlayerProps, index: number) => (
                <li key={index} className="fa-list-videos__content__list__item">
                    <VideoPlayer {...video} overlay={true} size="Medium" />
                </li>
            ))}
        </ul>
    );
}
