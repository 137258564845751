import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IFindDepartmentModel } from 'types';

const GoogleMapComponent = lazy(async () =>
    import('./fa-google-maps').then((module) => ({ default: module.GoogleMapComponent })),
);

const el = '.fa-component--google-map';
const DOM = document.querySelector(el);

const render = () => {
    if (DOM) {
        const googleMapsApiKey = DOM.getAttribute('data-google-maps-api-key') ?? '';
        const locationData = JSON.parse(DOM.getAttribute('data-locations') ?? '') ?? [];

        const offices: IFindDepartmentModel.IOffice[] = locationData.model.Offices ?? [];

        const officesModifiedWithIdentifiers = offices.map((office, index) => ({
            ...office,
            IsSelected: false,
            IsVisible: true,
            Id: office.OfficeName + index,
        }));

        createRoot(DOM).render(
            <GoogleMapComponent
                offices={officesModifiedWithIdentifiers}
                googleMapsApiKey={googleMapsApiKey}
                isLocationPage={true}
            />,
        );
    }
};

export default { render };
