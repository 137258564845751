import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const OfficeList = lazy(async () => import('./M14-office-list'));

const el = '.fa-component--office-list';
const DOM = document.querySelector(el);

const render = () => {
    if (DOM) {
        const officeData = JSON.parse(DOM.getAttribute('data-officeData')!) as Search.IMappedSearchResult[];
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;

        createRoot(DOM).render(<OfficeList results={officeData} labels={labels} />);
    }
};

export default {
    render,
};
