import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBenefitJumbotronProps } from './benefit-jumbotron';

const BenefitJumbotron = lazy(async () => import('./benefit-jumbotron'));

const el = '.fa-component--benefit-jumbotron';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const config = JSON.parse(DOM.getAttribute('data-props')!) as IBenefitJumbotronProps;

        createRoot(DOM).render(<BenefitJumbotron {...config} />);
    }
};

export default {
    render,
};
