import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const NewsList = lazy(async () => import('./news-list'));

const el = '.fa-component--news-list';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const results = JSON.parse(DOM.getAttribute('data-results')!) as Search.IMappedSearchResult[];
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;

        createRoot(DOM).render(<NewsList results={results} labels={labels} />);
    }
};

export default {
    render,
};
