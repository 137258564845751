import * as React from 'react';
import { Component } from 'react';
import truncate from 'truncate-html';
import { Search } from '../../types';
import verifyLabel from '../../foundation/methods/verifyLabel/verifyLabel';

interface IImageAccordionItemModel {
    name: string;
    title: string;
    imageUrl: string;
    open: boolean;
    description: string;
}

class ImageAccordionModel {
    items: Array<IImageAccordionItemModel>;
    constructor(items: Array<IImageAccordionItemModel>) {
        this.items = [...items];
    }
}

interface IImageAccordionProps {
    items: Array<IImageAccordionItemModel>;
    labels: Search.ILabels;
}

class ImageAccordion extends Component<IImageAccordionProps, ImageAccordionModel> {
    constructor(props: IImageAccordionProps) {
        super(props);
        this.state = new ImageAccordionModel(props.items);
    }

    toggleOpen = (event: any) => {
        const { id } = event.target;
        const items = [...this.state.items];

        if (items[id].open) {
            items[id].open = false;
        } else {
            items[id].open = true;
        }

        this.setState({
            items: items,
        });
    };
    createMarkup = (item: any, characterLimit: any) => {
        let description =
            item.description.length >= characterLimit && !item.open
                ? truncate(item.description, characterLimit)
                : item.description;
        var desc = description.replaceAll('¤¤', '"').replaceAll('¤', "'");
        return desc;
    };
    render() {
        const { items } = this.state;
        let characterLimit: number = 0;
        window.innerWidth >= 765 ? (characterLimit = 130) : (characterLimit = 80);

        return (
            <div className="fa-image-accordion">
                <div className="fa-block-narrow">
                    <div className="fa-image-accordion__list">
                        {items.map((item: IImageAccordionItemModel, index: number) => (
                            <div className="fa-image-accordion__item" key={index}>
                                <div className="fa-image-accordion__image-wrapper">
                                    <img src={item.imageUrl} alt="" />
                                </div>
                                <div className="fa-image-accordion__content">
                                    <h3 className="fa-image-accordion__title">{item.name}</h3>
                                    {item.title && <h4 className="fa-image-accordion__subtitle">{item.title}</h4>}
                                    {item.description && (
                                        <div
                                            className={
                                                'fa-image-accordion__body-text fa-rich-text' +
                                                (item.description.length >= characterLimit && !item.open
                                                    ? ' is-truncated'
                                                    : ' is-allvisible')
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: this.createMarkup(item, characterLimit),
                                            }}
                                        />
                                    )}
                                    {item.description && item.description.length > characterLimit && (
                                        <span
                                            className="fa-image-accordion__button fa-atom-link-primary"
                                            onClick={this.toggleOpen}
                                            id={index.toString()}
                                        >
                                            {!item.open
                                                ? verifyLabel('showMore', this.props.labels)
                                                : verifyLabel('showLess', this.props.labels)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageAccordion;
