import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IHowToItemProps } from './how-to-item';

const HowToItem = lazy(async () => import('./how-to-item'));

const el = '.fa-component--how-to-item';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IHowToItemProps;

            createRoot(component).render(
                <HowToItem header={props.header} description={props.description} stepIndex={props.stepIndex} />,
            );
        });
    }
};

export default {
    render,
};
