import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBookingAccordionProps } from './booking-accordion';

const BookingAccordion = lazy(async () => import('./booking-accordion'));

const el = '.fa-component--booking-accordion';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IBookingAccordionProps;

            createRoot(component).render(<BookingAccordion accordions={props.accordions} />);
        });
    }
};

export default {
    render,
};
