import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBenefitCardProps } from './benefit-card';

const BenefitCard = lazy(async () => import('./benefit-card'));

const el = '.fa-component--single-benefit-card';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IBenefitCardProps;

            createRoot(component).render(<BenefitCard {...props} />);
        });
    }
};

export default {
    render,
};
