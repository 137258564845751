import * as React from 'react';
import * as ReactDOM from 'react-dom';

import SocialShare from './fa-social-share';

const el = '.fa-component--social-share';
const DOM = document.querySelector(el) as HTMLDivElement;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.dataset.props || '{}');
        ReactDOM.render(
            <SocialShare
                socialShareList={props.socialShareList}
                pageUrl={props.pageUrl}
                pageHeader={props.pageHeader}
                soMeImageUrl={props.soMeImageUrl}
                colorScheme={props.colorScheme}
            />,
            DOM
        );
    }
};

export default {
    render,
};
