/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import type { IListOfVideosProps } from './list-of-videos';
import ListOfVideos from './list-of-videos';

const el = '.fa-component--list-of-videos-block';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM.length) {
        DOM.forEach((list) => {
            const data = list.getAttribute('data-props')
                ? JSON.parse(list.getAttribute('data-props') ?? '{}')
                : undefined;

            const props: IListOfVideosProps = {
                videos: data?.videos ?? '[]',
                defaultRows: data?.defaultRows ?? 2,
                header: data?.header ?? '',
                loadMore: data?.loadMore ?? '',
                storybook: false,
                videoFunctionCookieRequirementText: data?.videoFunctionCookieRequirementText ?? '',
                videoFunctionCookieRequirementButtonText: data?.videoFunctionCookieRequirementButtonText ?? '',
                videoCookiePlaceholderIconUrl: data?.videoCookiePlaceholderIconUrl ?? '',
            };

            createRoot(list).render(<ListOfVideos {...props} />);
        });
    }
};

export default {
    render,
};
