import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';

const SearchComponentNoResult = lazy(async () => import('./search-component-no-result'));

const el = '.fa-component--SearchComponentNoResults';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const message = DOM.getAttribute('data-message')!;

        createRoot(DOM).render(<SearchComponentNoResult noResultMessage={message} />);
    }
};

export default {
    render,
};
