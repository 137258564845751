import * as React from 'react';
import * as ReactDOM from 'react-dom';

import ImageAccordion from './M18-image-accordion';

const el = '.fa-component--image-accordion';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM.length) {
        DOM.forEach((accordion: any) => {
            const props = JSON.parse(accordion.dataset.props || '{}');
            const labels = JSON.parse(accordion.dataset.labels || '{}');

            ReactDOM.render(<ImageAccordion items={props.items} labels={labels} />, accordion);
        });
    }
};

export default {
    render,
};
