import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { ICountrySiteBannerProps } from './C20-country-site-banner';

const CountrySiteBanner = lazy(async () => import('./C20-country-site-banner'));

const el = '.fa-component--country-site-banner';
const DOM: HTMLElement = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.dataset.props ?? '{}') as ICountrySiteBannerProps;

        createRoot(DOM).render(
            <CountrySiteBanner
                header={props.header}
                subHeader={props.subHeader}
                globalButtonLabel={props.globalButtonLabel}
                globalButtonUrl={props.globalButtonUrl}
                visitCountrySiteButtonLabel={props.visitCountrySiteButtonLabel}
                visitCountrySiteUrl={props.visitCountrySiteUrl}
                countrySelectorList={props.countrySelectorList}
                currentCountry={props.currentCountry}
            />,
        );
    }
};

export default {
    render,
};
