import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IFaqCollectionBlockProps } from './faq-collection-block';

const FaqCollectionBlock = lazy(async () => import('./faq-collection-block'));

const el = '.fa-component--faq-collection-block';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IFaqCollectionBlockProps;

            createRoot(component).render(
                <FaqCollectionBlock header={props.header} subheader={props.subheader} faqblocks={props.faqblocks} />,
            );
        });
    }
};

export default {
    render,
};
