// eslint-disable-next-line import/no-unresolved
import VideoPlayer from '@components/M06-video-player/video-player';
// eslint-disable-next-line import/no-unresolved
import SvgClose from '@icons/SvgClose';
import * as React from 'react';

export interface IVideoOverlayProps {
    header?: string;
    abstract?: string;
    twentyThreeVideoPlayerCode: string;
    shown?: boolean;
    storybook?: boolean;
    onCloseCallback?: () => void;
    guid: string;
}

export default function VideoOverlay({
    header,
    abstract,
    twentyThreeVideoPlayerCode,
    shown = false,
    storybook = false,
    guid,
    onCloseCallback,
}: IVideoOverlayProps) {
    const overlayCloseButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        overlayCloseButtonRef.current?.focus();
    }, [shown]);

    const onFocusSetCloseButtonFocus = () => {
        overlayCloseButtonRef.current?.focus();
    };

    return (
        <div onClick={onCloseCallback} className={`fa-video-overlay ${shown ? 'fa-video-overlay--shown' : ''}`}>
            <div className="fa-video-overlay__content fa-container">
                <div className="fa-video-overlay__content__header">
                    {header ? <h2 className="fa-video-overlay__content__header__title">{header}</h2> : ''}
                    <button
                        ref={overlayCloseButtonRef}
                        onClick={onCloseCallback}
                        className="fa-video-overlay__content__header__close"
                        aria-label="Close overlay"
                    >
                        <SvgClose />
                    </button>
                </div>

                {shown && (
                    <VideoPlayer
                        twentyThreeVideoPlayerCode={twentyThreeVideoPlayerCode}
                        storybook={storybook}
                        header={header}
                        abstract={abstract}
                        size="Large"
                        overlay={true}
                        videoIsPlaying={true}
                        guid={guid}
                    />
                )}

                <button className="tab-trap-button-hidden" onFocus={onFocusSetCloseButtonFocus}></button>
            </div>
        </div>
    );
}
