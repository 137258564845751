import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IErrorMessageProps } from './error-message';

const ErrorMessage = lazy(async () => import('./error-message'));

const el = '.fa-component--error-message';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IErrorMessageProps;

            createRoot(component).render(<ErrorMessage message={props.message} />);
        });
    }
};

export default {
    render,
};
