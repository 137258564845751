import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const JobList = lazy(async () => import('./job-list'));

const el = '.fa-component--job-list';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;
        const results = JSON.parse(DOM.getAttribute('data-result')!) as Search.IMappedSearchResult[];

        createRoot(DOM).render(<JobList results={results} labels={labels} />);
    }
};

export default {
    render,
};
