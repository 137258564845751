import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IToastProps } from './toast';

const Toast = lazy(async () => import('./toast'));

const el = '.fa-component--toast';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IToastProps;

            createRoot(component).render(
                <Toast title={props.title} message={props.message} type={props.type} onClose={props.onClose} />,
            );
        });
    }
};

export default {
    render,
};
