import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IWarningBannerProps } from './C21-warning-banner';

// Importing WarningBanner component using React.lazy
const WarningBanner = lazy(async () => import('./C21-warning-banner'));

const el = '.fa-component--warning-banner';
const DOM: HTMLElement = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.dataset.props ?? '{}') as IWarningBannerProps;

        // Using createRoot to render the lazy-loaded component
        createRoot(DOM).render(<WarningBanner {...props} />);
    }
};

export default {
    render,
};
