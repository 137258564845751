// eslint-disable-next-line import/no-unresolved
import VideoPlayer from '@components/M06-video-player/video-player';
import type { IVideoPlaceholderImageProps } from '@components/video-placeholder-image/video-placeholder-image';
// eslint-disable-next-line import/no-unresolved
import VideoPlaceholderImage from '@components/video-placeholder-image/video-placeholder-image';
import * as React from 'react';

export interface IHomeHeroVideoProps {
    twentyThreeVideoPlayerCode: string;
    guid: string;
    placeholder: IVideoPlaceholderImageProps;
    placeholderMobile: IVideoPlaceholderImageProps;
    videoFunctionCookieRequirementText?: string;
    videoFunctionCookieRequirementButtonText?: string;
    videoCookiePlaceholderIconUrl?: string;
    storybook: boolean;
}

export default function HomeHeroVideo({
    twentyThreeVideoPlayerCode,
    guid,
    placeholder,
    placeholderMobile,
    videoFunctionCookieRequirementText,
    videoFunctionCookieRequirementButtonText,
    videoCookiePlaceholderIconUrl,
    storybook,
}: IHomeHeroVideoProps) {
    const [isPlayning, setIsPlaying] = React.useState(false);
    const [cookieConsent, setCookieConsent] = React.useState(false);
    const [iframeSrc, setIframeSrc] = React.useState(twentyThreeVideoPlayerCode);
    const [classNameModifier, setClassNameModifier] = React.useState('');
    const [isMobileSize, setIsMobileSize] = React.useState(false);

    React.useEffect(() => {
        if (storybook) {
            setCookieConsent(true);
        } else {
            handleCookieConsent();
        }

        window.addEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        if (isPlayning && !cookieConsent) {
            setClassNameModifier('fa-video-player__container--playing fa-video-player__container--consent-needed');
        } else if (isPlayning) {
            setClassNameModifier('fa-video-player__container--playing');
        } else if (cookieConsent) {
            setClassNameModifier('fa-video-player__container--consent');
        } else {
            setClassNameModifier('');
        }
    }, [isPlayning, cookieConsent]);

    const handleResize = () => {
        setIsMobileSize(window.innerWidth < 576);
    };

    const handleCookieConsent = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        const consent = CookieInformation.getConsentGivenFor('cookie_cat_functional');

        window.addEventListener(
            'CookieInformationConsentGiven',
            () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
                    setCookieConsent(CookieInformation.getConsentGivenFor('cookie_cat_functional'));
                    setIframeSrc(twentyThreeVideoPlayerCode);
                } else {
                    setIframeSrc('');
                }
            },
            false,
        );

        setCookieConsent(Boolean(consent));
    };

    const playVideo = () => {
        setIsPlaying(true);
    };

    return (
        <section className="fa-home-hero-video__media-container">
            <div className={`fa-video-player__container ${classNameModifier}`}>
                <div className="fa-video-player__container__placeholder" onClick={playVideo}>
                    <div className={isMobileSize ? 'fa-video-player__container__placeholder--hide' : ''}>
                        <VideoPlaceholderImage src={placeholder.src} alt={placeholder.alt} />
                    </div>
                    <div className={isMobileSize ? '' : 'fa-video-player__container__placeholder--hide'}>
                        <VideoPlaceholderImage src={placeholderMobile.src} alt={placeholderMobile.alt} />
                    </div>
                </div>

                <VideoPlayer
                    videoIsPlaying={isPlayning}
                    guid={guid}
                    twentyThreeVideoPlayerCode={iframeSrc}
                    videoFunctionCookieRequirementText={videoFunctionCookieRequirementText}
                    videoFunctionCookieRequirementButtonText={videoFunctionCookieRequirementButtonText}
                    videoCookiePlaceholderIconUrl={videoCookiePlaceholderIconUrl}
                    size="Medium"
                    storybook={storybook}
                    onCloseCallback={() => {
                        setIsPlaying(false);
                    }}
                />
            </div>
        </section>
    );
}
