// eslint-disable-next-line import/no-unresolved
import CookiesBlockedPlaceholder from '@components/cookies-blocked-placeholder/cookies-blocked-placeholder';
// eslint-disable-next-line import/no-unresolved
import VideoOverlay from '@components/video-overlay/video-overlay';
import * as React from 'react';

export interface IVideoPlayerProps {
    twentyThreeVideoPlayerCode?: string;
    size?: 'Medium' | 'Large';
    header?: string;
    abstract?: string;
    videoFunctionCookieRequirementText?: string;
    videoFunctionCookieRequirementButtonText?: string;
    videoCookiePlaceholderIconUrl?: string;
    guid: string;
    videoIsPlaying?: boolean;
    storybook?: boolean;
    overlay?: boolean;
    onCloseCallback?: () => void;
}

export default function VideoPlayer({
    twentyThreeVideoPlayerCode = '',
    size = 'Medium',
    header,
    abstract,
    videoFunctionCookieRequirementText,
    videoFunctionCookieRequirementButtonText,
    videoCookiePlaceholderIconUrl,
    videoIsPlaying = false,
    guid,
    storybook = false,
    overlay = false,
    onCloseCallback,
}: IVideoPlayerProps) {
    const [cookieConsent, setCookieConsent] = React.useState(false);
    const [iframeSrc, setIframeSrc] = React.useState(twentyThreeVideoPlayerCode);

    React.useEffect(() => {
        if (storybook) {
            setCookieConsent(true);
        } else {
            handleCookieConsent();
        }
    }, []);

    const handleCookieConsent = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        const consent = CookieInformation.getConsentGivenFor('cookie_cat_functional');

        window.addEventListener(
            'CookieInformationConsentGiven',
            () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
                    setCookieConsent(CookieInformation.getConsentGivenFor('cookie_cat_functional'));
                    setIframeSrc(twentyThreeVideoPlayerCode);
                } else {
                    setIframeSrc('');
                }
            },
            false,
        );

        setCookieConsent(Boolean(consent));
    };

    return cookieConsent ? (
        <Video
            twentyThreeVideoPlayerCode={iframeSrc}
            size={size}
            header={header}
            abstract={abstract}
            guid={guid}
            storybook={storybook}
            overlay={overlay}
            videoIsPlaying={videoIsPlaying}
            onCloseCallback={onCloseCallback}
        />
    ) : (
        <CookiesBlockedPlaceholder
            header={header ?? ''}
            abstract={abstract ?? ''}
            text={videoFunctionCookieRequirementText ?? ''}
            buttonText={videoFunctionCookieRequirementButtonText ?? ''}
            icon={videoCookiePlaceholderIconUrl ?? ''}
            size={size}
        />
    );
}

function Video({
    twentyThreeVideoPlayerCode = '',
    size = 'Medium',
    header,
    guid,
    abstract,
    storybook = false,
    videoIsPlaying = false,
    onCloseCallback,
}: IVideoPlayerProps) {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [overlayShown, setOverlayShown] = React.useState(false);
    const [isMobileView, setIsMobileView] = React.useState(false);

    const playerContainerRef = React.useRef<HTMLDivElement>(null);

    const iframeStyles: React.CSSProperties = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
    };

    const playVideo = () => {
        setIsPlaying(true);

        if (size === 'Medium') {
            setOverlayShown(true);
            document.addEventListener('keyup', closeOverlayOnEscape, false);
        }
    };

    const closeOverlayOnEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeOverlay();

            const button = playerContainerRef.current?.querySelector(
                '.fa-video-play-button',
            ) as HTMLButtonElement | null;

            button?.focus();
        }
    };

    const closeOverlay = () => {
        setOverlayShown(false);
        setIsPlaying(false);
        document.removeEventListener('keyup', closeOverlayOnEscape, false);

        if (typeof onCloseCallback === 'function') {
            onCloseCallback();
        }
    };

    React.useEffect(() => {
        if (isPlaying) {
            const iframe = document.getElementById(guid) as HTMLIFrameElement | null;

            if (iframe) {
                setTimeout(() => {
                    iframe.src += '&autoPlay=1';
                }, 10);
            }
        }
    }, [isPlaying]);

    React.useEffect(() => {
        setOverlayShown(videoIsPlaying);
        setIsPlaying(videoIsPlaying);
    }, [videoIsPlaying]);

    const disableOverlayOnMobile = () => {
        const width = window.innerWidth;

        if (width < 576) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    };

    React.useEffect(() => {
        disableOverlayOnMobile();
        window.addEventListener('resize', disableOverlayOnMobile, false);
    }, []);

    return (
        <article className={`fa-video-player ${size === 'Medium' ? 'fa-block-narrow' : 'fa-block'}`}>
            <h2 className="fa-video-player__title">{header}</h2>
            <div ref={playerContainerRef} className="fa-video-player__container">
                {size === 'Medium' && !isMobileView ? (
                    <div>
                        <VideoOverlay
                            header={header}
                            abstract={abstract}
                            shown={overlayShown}
                            twentyThreeVideoPlayerCode={twentyThreeVideoPlayerCode ?? ''}
                            storybook={storybook}
                            onCloseCallback={closeOverlay}
                            guid={guid}
                        />

                        <div className="fa-video-player__container__effect" onClick={playVideo}>
                            <iframe
                                className="fa-video-player__container__iframe--placeholder"
                                data-category-consent="cookie_cat_functional"
                                src={twentyThreeVideoPlayerCode ?? ''}
                                data-consent-src={twentyThreeVideoPlayerCode}
                                style={iframeStyles}
                                allowFullScreen={true}
                                allow="fullscreen"
                            ></iframe>
                        </div>
                    </div>
                ) : (
                    <iframe
                        id={guid}
                        className="fa-video-player__container__iframe"
                        data-category-consent="cookie_cat_functional"
                        src={twentyThreeVideoPlayerCode ?? ''}
                        data-consent-src={twentyThreeVideoPlayerCode}
                        style={iframeStyles}
                        allowFullScreen={true}
                        allow="autoplay; fullscreen"
                    ></iframe>
                )}
            </div>
            <p className="fa-video-player__abstract">{abstract}</p>
        </article>
    );
}
