/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import HomeHeroVideo from './home-hero-video';

const el = '.fa-component--home-hero-video';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM.length) {
        DOM.forEach((hero) => {
            const data = hero.getAttribute('data-props')
                ? JSON.parse(hero.getAttribute('data-props') ?? '{}')
                : undefined;

            const props = {
                twentyThreeVideoPlayerCode: data.twentyThreeVideoPlayerCode ?? '',
                guid: data.guid ?? '',
                videoFunctionCookieRequirementText: data.videoFunctionCookieRequirementText ?? '',
                videoFunctionCookieRequirementButtonText: data.videoFunctionCookieRequirementButtonText ?? '',
                videoCookiePlaceholderIconUrl: data.videoCookiePlaceholderIconUrl ?? '',
                placeholder: data.placeholder ?? undefined,
                placeholderMobile: data.placeholderMobile ?? undefined,
                storybook: false,
            };

            createRoot(hero).render(<HomeHeroVideo {...props} />);
        });
    }
};

export default {
    render,
};
