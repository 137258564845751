import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IErrorCardProps } from './error-card';

const ErrorCard = lazy(async () => import('./error-card'));

const el = '.fa-component--error-card';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IErrorCardProps;

            createRoot(component).render(<ErrorCard icon={props.icon} title={props.title} message={props.message} />);
        });
    }
};

export default {
    render,
};
