import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IProductJumbotronProps } from './product-jumbotron';

const ProductJumbotron = lazy(async () => import('./product-jumbotron'));

const el = '.fa-component--product-jumbotron';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const config = JSON.parse(DOM.getAttribute('data-props')!) as IProductJumbotronProps;

        createRoot(DOM).render(<ProductJumbotron {...config} />);
    }
};

export default {
    render,
};
