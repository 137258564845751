import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IPersonalProductListProps } from './personal-product-list';

const PersonalProductList = lazy(async () => import('./personal-product-list'));

const el = '.fa-component--personal-product-list';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IPersonalProductListProps;

            createRoot(component).render(<PersonalProductList {...props} />);
        });
    }
};

export default {
    render,
};
