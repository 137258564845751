import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';

const Employees = lazy(async () => import('./fa-employees').then((module) => ({ default: module.Employees })));

const el = '.fa-component--employees';
const DOM = document.querySelector(el);

const render = () => {
    if (DOM) {
        const pageContentGuid: string = DOM.getAttribute('data-page-content-guid') ?? '';
        const blockContetGuid: string = DOM.getAttribute('data-block-content-guid') ?? '';
        const heading: string = DOM.getAttribute('data-employee-heading') ?? '';

        createRoot(DOM).render(
            <Employees pageContentGuid={pageContentGuid} blockContentGuid={blockContetGuid} heading={heading} />,
        );
    }
};

export default { render };
