import * as React from 'react';

const SvgClose = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.72667 8L12 11.2733L11.2733 12L8 8.72667L4.72667 12L4 11.2733L7.27333 8L4 4.72667L4.72667 4L8 7.27333L11.2733 4L12 4.72667L8.72667 8Z"
            fill="#4D4D4D"
        />
    </svg>
);

export default SvgClose;
