import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IFaqItemProps } from './faq-item';

const FaqItem = lazy(async () => import('./faq-item'));

const el = '.fa-component--faq-item';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM) {
        DOM.forEach((component) => {
            const props = JSON.parse(component.getAttribute('data-props')!) as IFaqItemProps;

            createRoot(component).render(
                <FaqItem question={props.question} answer={props.answer} themeColor={props.themeColor} />,
            );
        });
    }
};

export default {
    render,
};
