// eslint-disable-next-line import/no-unresolved
import SvgCookieFallback from '@icons/svgCookieFallback';
import * as React from 'react';

export interface ICookiesBlockedPlaceholderProps {
    header?: string;
    abstract?: string;
    text: string;
    buttonText: string;
    icon?: string;
    size?: 'Medium' | 'Large';
}

export default function CookiesBlockedPlaceholder({
    text,
    buttonText,
    header,
    abstract,
    icon,
    size = 'Medium',
}: ICookiesBlockedPlaceholderProps) {
    const renew = () => {
        // we disabled eslint because this function exist outside of this file via script in the _Layout.cshtml
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        CookieConsent.renew();
    };

    return (
        <article className={`fa-video-player ${size === 'Medium' ? 'fa-block-narrow' : 'fa-block'}`}>
            <h2 className="fa-video-player__title">{header}</h2>
            <div className="fa-cookies-blocked-placeholder">
                <div className="fa-cookies-blocked-placeholder__content">
                    <span className="fa-cookies-blocked-placeholder__content__icon">
                        {icon ? <img src={icon} alt="Cookie icon" /> : <SvgCookieFallback />}
                    </span>
                    <p className="fa-cookies-blocked-placeholder__content__text">{text}</p>
                    <div>
                        <button
                            onClick={renew}
                            className="fa-cookies-blocked-placeholder__content__button fa-atom-button fa-atom-button--color-red"
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>

            <p className="fa-video-player__abstract">{abstract}</p>
        </article>
    );
}
