import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IFindDepartmentModel } from 'types';

const DepartmentFinderDataProvider = lazy(async () =>
    import('./context/fa-department-finder-context').then((module) => ({
        default: module.DepartmentFinderDataProvider,
    })),
);
const DepartmentFinder = lazy(async () => import('./fa-department-finder'));

const el = '.fa-component--department-finder';
const DOM = document.querySelector(el);

const mapRawData = (rawData: IFindDepartmentModel.IFindDepartment) => {
    const data: IFindDepartmentModel.IFindDepartment = {
        FindUsLabels: {
            FalckOfficesLabel: rawData.FindUsLabels.FalckOfficesLabel ?? '',
            ColaboratorOfficesLabel: rawData.FindUsLabels.ColaboratorOfficesLabel ?? '',
            ListViewLabel: rawData.FindUsLabels.ListViewLabel ?? '',
            MapViewLabel: rawData.FindUsLabels.MapViewLabel ?? '',
            Heading: rawData.FindUsLabels.Heading ?? '',
        },
        Locations: rawData.Locations.map((location: IFindDepartmentModel.ILocation) => ({
            IsSelected: false,
            IsSelectedListView: false,
            ContentLink: { ...location.ContentLink },
            LocationName: location.LocationName ?? '',
            Offices: location.Offices.map((office: IFindDepartmentModel.IOffice, index) => ({
                ...office,
                Id: office.OfficeName + index,
                IsSelected: false,
                IsVisible: true,
            })),
        })),
        PageContentGuid: rawData.PageContentGuid,
        PageTitle: rawData.PageTitle ?? '',
    };

    data.Locations[0].IsSelectedListView = true;

    return data;
};

const render = () => {
    if (DOM) {
        const googleMapsApiKey = DOM.getAttribute('data-google-maps-api-key') ?? '';
        const dataLocation = DOM.getAttribute('data-location') ?? '';
        const rawData = JSON.parse(dataLocation).model as IFindDepartmentModel.IFindDepartment;
        const locationData = mapRawData(rawData);

        createRoot(DOM).render(
            <DepartmentFinderDataProvider>
                <DepartmentFinder locationData={locationData} googleMapsApiKey={googleMapsApiKey} />
            </DepartmentFinderDataProvider>,
        );
    }
};

export default { render };
