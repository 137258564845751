import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const SearchFilterOptions = lazy(async () => import('./search-filter-options'));

const el = '.fa-component--search-filter-options';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM.length) {
        DOM.forEach((filter) => {
            const config = JSON.parse(filter.getAttribute('data-config')!) as Search.IMappedFacetModel[];
            const facetStore = JSON.parse(filter.getAttribute('data-facetstore')!) as Search.IFacet[];
            const labels = JSON.parse(filter.getAttribute('data-labels')!) as Search.ILabels;

            createRoot(filter).render(<SearchFilterOptions config={config} facetStore={facetStore} labels={labels} />);
        });
    }
};

export default {
    render,
};
