import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const DocumentReports = lazy(async () => import('./M19-document-reports'));

const el = '.fa-component--document-reports';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const reportData = JSON.parse(DOM.getAttribute('data')!) as Search.IMappedSearchResult[];
        const labels = JSON.parse(DOM.getAttribute('data-labels')!) as Search.ILabels;

        createRoot(DOM).render(<DocumentReports result={reportData} labels={labels} />);
    }
};

export default {
    render,
};
