import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { ILanguageSelectorList } from 'types';

const DropdownLanguage = lazy(async () => import('./dropdown-with-language-selector'));

const el = '.fa-component--dropdown-with-language';
const DOM: HTMLElement = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const props = JSON.parse(DOM.dataset.props ?? '{}') as ILanguageSelectorList;

        createRoot(DOM).render(<DropdownLanguage languageSelector={props} />);
    }
};

export default {
    render,
};
