import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { Search } from 'types';

const DropdownWithSearch = lazy(async () => import('./dropdown-with-search'));

const el = '.fa-component--dropdown-with-search';
const DOM = document.querySelectorAll(el);

const render = function () {
    if (DOM.length) {
        DOM.forEach((dropdown) => {
            const list = JSON.parse(dropdown.getAttribute('data-list')!) as Search.IMappedFacetModel;
            const labels = JSON.parse(dropdown.getAttribute('data-labels')!) as Search.ILabels;

            createRoot(dropdown).render(<DropdownWithSearch label={labels} facets={list} />);
        });
    }
};

export default {
    render,
};
