import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IBookingHeroProps } from './booking-hero';

const BookingHero = lazy(async () => import('./booking-hero'));

const el = '.fa-component--booking-hero';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const config = JSON.parse(DOM.getAttribute('data-props')!) as IBookingHeroProps;

        createRoot(DOM).render(<BookingHero {...config} />);
    }
};

export default {
    render,
};
