import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { ICoveredObjectJumbotronProps } from './covered-object-jumbotron';

const CoveredObjectJumbotron = lazy(async () => import('./covered-object-jumbotron'));

const el = '.fa-component--covered-object-jumbotron';
const DOM = document.querySelector(el)!;

const render = function () {
    if (DOM) {
        const config = JSON.parse(DOM.getAttribute('data-props')!) as ICoveredObjectJumbotronProps;

        createRoot(DOM).render(<CoveredObjectJumbotron {...config} />);
    }
};

export default {
    render,
};
